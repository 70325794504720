import {Helmet} from "react-helmet-async";
import React, {useState, useEffect, useRef} from "react";
import {Box, Typography, Divider, IconButton, Grid, TableFooter} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {ArrowForward, Download} from "@mui/icons-material";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper} from '@mui/material';
import {generatePDF, getCurrentDate, getCurrentTime, extractDate} from "../../../../utils/helper";
import useStyles from "./partials/styles";
import VoucherRow from "./partials/VoucherRow";
import {toast} from "react-toastify";


function PaymentVoucher() {

  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const {row} = location.state || {};

  const imgRef = useRef(null);

  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  useEffect(() => {
    if (imgRef.current && imgRef.current.complete) {
      handleImageLoad();
    }
  }, []);

  const data = [
    {ar: "صرفنا إلى السيد / السادة", value: row?.debtor_account_name, en: "Received from Mr.\\ Messers"},
    {ar: "المبلغ وقدره", value: row?.debtor, en: "The sum of"},
    {ar: "وذلك عن", value: row?.statement, en: "for"},
  ];

  const signatures = [{name: "المحاسب"}, {name: "دقق"}, {name: "يعتمد"}];

  const tableHeaderData = ["م", "رقم الحساب", "اسم الحساب", "مدين", "دائن"];

  const tableBodyData = [
    {
      id: 1,
      accountNumber: row?.creditor_account_number,
      accountName: row?.creditor_account_name,
      debit: row?.debtor,
      credit: 0.0
    },
    {
      id: 2,
      accountNumber: row?.debtor_account_number,
      accountName: row?.debtor_account_name,
      debit: 0.0,
      credit: row?.creditor
    },
  ];

  function downloadPdf() {
    if (isImageLoaded)
      generatePDF("download-file")
    else
      toast.warn("انتظر حتى يتم تحميل جميع البيانات، ثم أعد المحاولة.")
  }

  return (
    <>
      <Helmet>
        <title>سند صرف</title>
      </Helmet>

      <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} mb={2}
           sx={{flexDirection: {xs: "column", sm: "row"}}}
      >
        <Box display={"flex"} alignItems={"center"}>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowForward/>
          </IconButton>
          <Typography>سند صرف</Typography>
        </Box>

        <Box>
          <IconButton onClick={() => downloadPdf()}>
            <Download/>
          </IconButton>
        </Box>
      </Box>

      <Divider/>

      <div id={"download-file"} style={{padding: "10px"}}>

        <Box bgcolor={"white"} p={2}>

          <Box mt={1} border={2} borderRadius={1} bgcolor={"white"}>

            <Grid container>
              <Grid item md={4} p={1}>
                <Typography variant={"caption"} fontWeight={"bold"}>
                  منور ومشاركوه <br/>
                  المرقاب - شارع عثمان بن عفان - برج العاصمة دور 50<br/>
                  50 <br/>
                  ص-ب: 1152 دسمان الرمز البريدي 15462 الكويت <br/>
                  00965 2294 2727 <br/>
                  00965 2294 2701 <br/>
                </Typography>
              </Grid>
              <Grid item md={4}>
                <Box display={"flex"} justifyContent={"center"} mt={-5} mb={-3}>
                  <img
                    ref={imgRef}
                    id="company-logo"
                    src={"/assets/images/logo/logo.png"}
                    alt="logo"
                    onLoad={handleImageLoad}
                    style={{height: "300px", width: "300px"}}
                  />
                </Box>
              </Grid>
              <Grid item md={4} p={1}>
                <Box display={"flex"} justifyContent={"end"}>
                  <Typography variant="caption" fontWeight="bold" className={classes.ltrText}>
                    Associates & Menwer <br/>
                    Sharq-Khalid Bin Al-waleed Street 25 February Tower <br/>
                    50 <br/>
                    O.P. Box: 1152 Dasman, Code 15462 Kuwait <br/>
                    2728 2294 00965 <br/>
                    info@menwer.com <br/>
                    www.menwer.com <br/>
                  </Typography>
                </Box>
                <Box
                  p={1} border={3} mt={1}
                  borderColor={"#706e6f"}
                  display={"flex"}
                  bgcolor={"white"}
                  textAlign={"center"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  alignContent={"center"}
                >
                  <Typography fontWeight={"bold"}>دينار</Typography>
                  <Typography fontWeight={"bold"}>{row?.debtor}</Typography>
                  <Typography fontWeight={"bold"}>K.D</Typography>
                </Box>
              </Grid>
            </Grid>

            <Grid container mt={-4}>

              <Grid item md={4.5} p={1} px={2}>
                <VoucherRow
                  arLabel={"رقم السند"}
                  value={{data: row?.id, dotted: true, bold: true, color: "red"}}
                />
                <VoucherRow
                  arLabel={"الفرع"} align={"start"}
                  value={{data: "مكتب منور للمحاماة", dotted: true, bold: true}}
                />
                <VoucherRow
                  arLabel={"التاريخ"} enLabel={"Date"} align={"start"}
                  value={{data: extractDate(row?.movement_date), dotted: true}}
                />
              </Grid>

              <Grid item md={3}>
                <Box mt={2} p={2} border={4} bgcolor={"white"} textAlign={"center"}>
                  <Typography fontWeight={"bold"}>سند صرف نقدي</Typography>
                  <Typography fontWeight={"bold"}>Cash Payment Voucher</Typography>
                </Box>
              </Grid>

              <Grid item md={4.5} p={1} mt={4}>
                <VoucherRow
                  arLabel={"بنك"} enLabel={"Bank"}
                  align={"end"} pl={4}
                  value={{data: "00", dotted: true}}
                />
                <VoucherRow
                  arLabel={"شيك رقم"} enLabel={"Check No"}
                  align={"end"} pl={4}
                  value={{data: 0, dotted: true}}
                />
              </Grid>

            </Grid>

            <Grid container mt={1} px={1}>
              {data.map((item) => {
                return (<Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
                  <Typography fontWeight="bold" pl={2}>
                    {item.ar}:
                  </Typography>
                  <Box className={classes.bigDotedText} flexGrow={1} mx={2}>
                    <Typography>{item.value}</Typography>
                  </Box>
                  <Typography fontWeight="bold" className={classes.ltrText} pr={2}>
                    {item.en}:
                  </Typography>
                </Box>);
              })}
            </Grid>

            <Grid container mt={12} px={1} mb={6} justifyContent={"space-around"}>
              <Grid item md={4.5} mt={2}>
                <VoucherRow
                  arLabel={"أمين الصندوق"}
                  enLabel={"Recipient"}
                  align={"center"}
                  value={{data: "", dotted: true}}
                />
              </Grid>

              <Grid item md={4} mt={1}>
                <VoucherRow
                  arLabel={"المحاسب"}
                  enLabel={"Acc"}
                  align={"center"}
                  value={{data: "رامي شريف", dotted: true}}
                />
              </Grid>

              <Grid item md={3} mt={2}>
                <Box display={"flex"} justifyContent={"center"}>
                  <Typography fontWeight={"bold"} pr={2} mb={2}>
                    المستلم
                  </Typography>
                </Box>
                <VoucherRow arLabel={"الاسم"} mt={2} value={{data: "", dotted: true}}/>
                <VoucherRow arLabel={"التوقيع"} mt={3} value={{data: "", dotted: true}}/>
              </Grid>
            </Grid>

          </Box>

          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table>
              <TableHead>
                <TableRow className={classes.headerRow}>
                  {tableHeaderData.map((cell) => {
                    return (
                      <TableCell align="center" className={classes.headerCell}>
                        {cell}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableBodyData.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell className={classes.cell}>{item.id}</TableCell>
                    <TableCell className={classes.cell} colSpan={2}>
                      <Box display={"flex"} justifyContent={"space-around"}>
                        <Typography>{item.accountNumber}</Typography>
                        <Typography>{item.accountName}</Typography>
                      </Box>
                      <Typography mt={2} sx={{backgroundColor: "#f5f5f5"}}>
                        {row?.statement}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.cell}>
                      <Typography mt={2} sx={{backgroundColor: "#f5f5f5"}}>
                        {item.debit}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.cell}>
                      <Typography mt={2} sx={{backgroundColor: "#f5f5f5"}}>
                        {item.credit}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow className={classes.headerRow}>
                  <TableCell align="center" className={classes.footerCell} colSpan={3}>
                    <Box display={"flex"} justifyContent={"end"}>
                      <Typography fontWeight={"bold"}>الاجمالي</Typography>
                    </Box>
                  </TableCell>
                  <TableCell align="center" className={classes.footerCell}>
                    <Typography fontWeight={"bold"}>{row?.debtor}</Typography>
                  </TableCell>
                  <TableCell align="center" className={classes.footerCell}>
                    <Typography fontWeight={"bold"}>{row?.creditor}</Typography>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>

          <Grid container my={2} justifyContent={"space-around"}>
            {signatures?.map((signature) => {
              return (
                <Grid item md={4}>
                  <Box textAlign={"center"}>
                    <Typography fontWeight={"bold"}>{signature.name}</Typography>
                    <Box className={classes.dotedText} mt={2} width={"50%"}>&nbsp;</Box>
                  </Box>
                </Grid>
              );
            })}
          </Grid>

          <Divider sx={{bgcolor: "black", mt: 30}}/>

          <Box px={6} textAlign={"end"}>
            <Typography fontWeight={"bold"} mt={2}>{getCurrentDate()}</Typography>
            <Typography fontWeight={"bold"}>{getCurrentTime()}</Typography>
          </Box>

        </Box>

      </div>
    </>
  );
}

export default PaymentVoucher;