import React from "react";
import {Box, Typography} from "@mui/material";
import useStyles from "../partials/styles";

function VoucherRow({value, enLabel, arLabel, align, fullWidth, ...rest}) {
  const classes = useStyles();

  return (
    <Box width={"100%"} mb={1} {...rest}>
      <Box display="flex" alignItems="center" justifyContent={align ?? "start"}>
        {arLabel && (
          <Typography fontWeight={"bold"} pr={2}>
            {arLabel}:
          </Typography>
        )}
        {value?.dotted ? (
          <Box className={fullWidth ? classes.bigDotedText : classes.dotedText} flexGrow={1}>
            <Typography
              fontWeight={value?.bold ? "bold" : "text"} color={value?.color ?? ""}
            >
              {value?.data ?? ""}
            </Typography>
          </Box>
        ) : (
          <Typography fontWeight={value?.bold ? "bold" : "text"}>{value?.data ?? ""}</Typography>
        )}
        {enLabel && (
          <Typography fontWeight={"bold"} pl={2}>
            :{enLabel}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export default VoucherRow;
