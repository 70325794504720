import { createSlice } from "@reduxjs/toolkit";
import {
  getAttachmentTypeListService,
  getAttachmentListService,
  uploadAttachmentServices,
  deleteAttachmentServices,
  filterAttachmentsServices,
  updateAttachmentsServices,
  getAttachmentByClient,
  //
  uploadAttachmentClientServices,
  //
  changeAttachmentClientServices,
  sendAttachmentClientServices,
  //
  deleteAttachmentClientServices,
} from "../services/attachment_service";
import { AttachmentsSessionColumnsList } from "../../utils/columnsLists";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isLoadingDelete: false,
  attachmentType: [],
  isLoadingAttachmentList: false,
  attachmentList: [],
  AttachmentsSessionColumnsList: AttachmentsSessionColumnsList,
  isLoadingFilter: false,
  isLoadingUpdate: false,
  //
  isLoadingAttachmentClientList: false,
  attachmentClientList: [],
  isLoadingUpload: false,
  //
  isLoadingSend: false,
  isLoadingCahngeName: false,
  attachmentId: null,
  //
  isLoadingDeleteAtt: false,
  //
  isLoadingAtt: false,
  attCount: null,
};

const slice = createSlice({
  name: "attachment",
  initialState,
  reducers: {
    setAttachmentsSessionColumnsList: (state, action) => {
      state.AttachmentsSessionColumnsList = action.payload;
    },

    setAttachmentId: (state, action) => {
      state.attachmentId = action.payload;
    },
  },
  extraReducers: {
    // GET =>  All ATTACHMENT TYPE
    [getAttachmentTypeListService.fulfilled]: (state, action) => {
      state.isLoadingAttachmentList = false;
      state.attachmentType = action.payload?.data?.data;
    },
    [getAttachmentTypeListService.rejected]: (state, action) => {
      state.isLoadingAttachmentList = false;
    },
    [getAttachmentTypeListService.pending]: (state) => {
      state.isLoadingAttachmentList = true;
    },

    // GET =>  All ATTACHMENT Service
    [getAttachmentListService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.attachmentList = action.payload?.data?.data;
      state.attCount = action.payload?.data?.count;
    },
    [getAttachmentListService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getAttachmentListService.pending]: (state) => {
      state.isLoading = true;
    },

    // POST =>  UPLOAD ATTACHMENT
    [uploadAttachmentServices.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isLoadingAtt = false;
    },
    [uploadAttachmentServices.rejected]: (state, action) => {
      state.isLoading = false;
      state.isLoadingAtt = false;
    },
    [uploadAttachmentServices.pending]: (state) => {
      state.isLoading = true;
      state.isLoadingAtt = true;
    },

    // POST =>  UPLOAD ATTACHMENT
    [deleteAttachmentServices.fulfilled]: (state, action) => {
      state.isLoadingDelete = false;
    },
    [deleteAttachmentServices.rejected]: (state, action) => {
      state.isLoadingDelete = false;
    },
    [deleteAttachmentServices.pending]: (state) => {
      state.isLoadingDelete = true;
    },

    // POST =>  FILTER ATTACHMENT
    [filterAttachmentsServices.fulfilled]: (state, action) => {
      state.isLoadingFilter = false;
      state.isLoading = false;
      state.attachmentList = action.payload?.data;
    },
    [filterAttachmentsServices.rejected]: (state, action) => {
      state.isLoadingFilter = false;
      state.isLoading = false;
    },
    [filterAttachmentsServices.pending]: (state) => {
      state.isLoadingFilter = true;
      state.isLoading = true;
    },
    // PUT =>  FILTER ATTACHMENT
    [updateAttachmentsServices.fulfilled]: (state, action) => {
      state.isLoadingUpdate = false;
    },
    [updateAttachmentsServices.rejected]: (state, action) => {
      state.isLoadingUpdate = false;
    },
    [updateAttachmentsServices.pending]: (state) => {
      state.isLoadingUpdate = true;
    },

    // GET =>  Attachment  client
    [getAttachmentByClient.fulfilled]: (state, action) => {
      state.isLoadingAttachmentClientList = false;
      state.attachmentClientList = action.payload?.data?.data;
      state.count = action.payload?.data?.count;
    },
    [getAttachmentByClient.rejected]: (state, action) => {
      state.isLoadingAttachmentClientList = false;
    },
    [getAttachmentByClient.pending]: (state) => {
      state.isLoadingAttachmentClientList = true;
    },

    // POST =>  UPLOAD ATTACHMENT FOR CLIENT
    [uploadAttachmentClientServices.fulfilled]: (state, action) => {
      state.isLoadingUpload = false;
    },
    [uploadAttachmentClientServices.rejected]: (state, action) => {
      state.isLoadingUpload = false;
    },
    [uploadAttachmentClientServices.pending]: (state) => {
      state.isLoadingUpload = true;
    },

    // PUT =>  change name
    [changeAttachmentClientServices.fulfilled]: (state, action) => {
      state.isLoadingCahngeName = false;
    },
    [changeAttachmentClientServices.rejected]: (state, action) => {
      state.isLoadingCahngeName = false;
    },
    [changeAttachmentClientServices.pending]: (state) => {
      state.isLoadingCahngeName = true;
    },

    // get =>  send by email
    [sendAttachmentClientServices.fulfilled]: (state, action) => {
      state.isLoadingSend = false;
    },
    [sendAttachmentClientServices.rejected]: (state, action) => {
      state.isLoadingSend = false;
    },
    [sendAttachmentClientServices.pending]: (state) => {
      state.isLoadingSend = true;
    },

    // delete =>  delete client attachment
    [deleteAttachmentClientServices.fulfilled]: (state, action) => {
      state.isLoadingDeleteAtt = false;
    },
    [deleteAttachmentClientServices.rejected]: (state, action) => {
      state.isLoadingDeleteAtt = false;
    },
    [deleteAttachmentClientServices.pending]: (state) => {
      state.isLoadingDeleteAtt = true;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setAttachmentsSessionColumnsList, setAttachmentId } =
  slice.actions;
