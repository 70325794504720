import { createSlice } from "@reduxjs/toolkit";
import {
  deleteSessionService,
  filterAdministrativeService,
  filterCourtsService,
  filterExcuteService,
  filterExpertsService,
  getAllCourtSessionService,
  updateProsedureStatement,
  updateSessionService,
  getProceduresService,
} from "../services/sessions_service";
import { SessionsColumnsList } from "../../utils/columnsLists";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  sessions: [],
  count: null,
  SessionsColumnsList: SessionsColumnsList,
  isLoadingFilter: false,
  isLoadingDelete: false,
  proceduers: [],
  metaProceduers: null,
};

const slice = createSlice({
  name: "sessions",
  initialState,
  reducers: {
    setColumnsList: (state, action) => {
      state.isLoading = true;
      state.columnsList = action.payload;
      state.isLoading = false;
    },
  },
  extraReducers: {
    // ==> GET COURTS SESSION SERVICE
    [getAllCourtSessionService.fulfilled]: (state, action) => {
      state.isLoadingFilter = false;
      state.sessions = action.payload.data.data;
      state.count = action.payload.data.count;
    },
    [getAllCourtSessionService.rejected]: (state, action) => {
      state.isLoadingFilter = false;
    },
    [getAllCourtSessionService.pending]: (state) => {
      state.isLoadingFilter = true;
    },

    // POST =>  UPDATE DELEGTION NOTE
    [filterCourtsService.fulfilled]: (state, action) => {
      state.isLoadingFilter = false;
      state.isLoading = false;

      state.sessions = action.payload.data.data;
      state.count = action.payload.data.count;
    },
    [filterCourtsService.rejected]: (state, action) => {
      state.isLoadingFilter = false;
      state.isLoading = false;
    },
    [filterCourtsService.pending]: (state) => {
      state.isLoadingFilter = true;
      state.isLoading = true;
    },

    // POST => Filter expert
    [filterExpertsService.fulfilled]: (state, action) => {
      state.isLoadingFilter = false;
      state.isLoading = false;
      state.sessions = action.payload.data.data;
      state.count = action.payload.data.count;
    },
    [filterExpertsService.rejected]: (state, action) => {
      state.isLoadingFilter = false;
      state.isLoading = false;
    },
    [filterExpertsService.pending]: (state) => {
      state.isLoadingFilter = true;
      state.isLoading = true;
    },
    // POST => Filter expert
    [filterAdministrativeService.fulfilled]: (state, action) => {
      state.isLoadingFilter = false;
      state.isLoading = false;
      state.sessions = action.payload.data.data;
      state.count = action.payload.data.count;
    },
    [filterAdministrativeService.rejected]: (state, action) => {
      state.isLoadingFilter = false;
      state.isLoading = false;
    },
    [filterAdministrativeService.pending]: (state) => {
      state.isLoadingFilter = true;
      state.isLoading = true;
    },
    // POST => Filter expert
    [filterExcuteService.fulfilled]: (state, action) => {
      state.isLoadingFilter = false;
      state.isLoading = false;
      state.sessions = action.payload.data.data;
      state.count = action.payload.data.count;
    },
    [filterExcuteService.rejected]: (state, action) => {
      state.isLoadingFilter = false;
      state.isLoading = false;
    },
    [filterExcuteService.pending]: (state) => {
      state.isLoadingFilter = true;
      state.isLoading = true;
    },
    // POST => Filter expert
    [deleteSessionService.fulfilled]: (state, action) => {
      state.isLoadingDelete = false;
    },
    [deleteSessionService.rejected]: (state, action) => {
      state.isLoadingDelete = false;
    },
    [deleteSessionService.pending]: (state) => {
      state.isLoadingDelete = true;
    },
    // POST => Filter expert
    [updateSessionService.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [updateSessionService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [updateSessionService.pending]: (state) => {
      state.isLoading = true;
    },
    // POST => Filter expert
    [updateProsedureStatement.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [updateProsedureStatement.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [updateProsedureStatement.pending]: (state) => {
      state.isLoading = true;
    },

    // GET =>  GET PROCEDURES
    [getProceduresService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.proceduers = action.payload.data.data;
      state.metaProceduers = action.payload.data;
    },
    [getProceduresService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getProceduresService.pending]: (state) => {
      state.isLoading = true;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setColumnsList } = slice.actions;
