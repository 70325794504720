import { createAsyncThunk } from "@reduxjs/toolkit";
// urls
import * as URLS from "../../urls";
//axios
import axios from "../../utils/axios";
//_________________________________________________

// GET =>  All ATTACHMENT TYPE
export const getAttachmentTypeListService = createAsyncThunk(
  "attachment/getAttachmentTypeList",
  async (thunkAPI) => {
    try {
      const response = await axios.get(URLS.attachmentTypeURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  All ATTACHMENT Service
export const getAttachmentListService = createAsyncThunk(
  "attachment/attachmentList",
  async (params, thunkAPI) => {
    try {
      console.log("params", params);
      const response = await axios.get(
        `${URLS.attachmentListURL}/${params.id}`,
        {
          params: {
            ...params,
          },
        }
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  UPLOAD ATTACHMENT
export const uploadAttachmentServices = createAsyncThunk(
  "attachment/uploadAttachment",
  async (data, thunkAPI) => {
    console.log("attachment data ======>", data);
    try {
      const response = await axios.post(URLS.uploadAttachmentURL, data, {
        headers: {
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  delete Attachment Services
export const deleteAttachmentServices = createAsyncThunk(
  "attachment/deleteAttachment",
  async (id, thunkAPI) => {
    try {
      const response = await axios.delete(
        `${URLS.attachmentsURL}/${id}/delete/`
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  FILTER ATTACHMENT
export const filterAttachmentsServices = createAsyncThunk(
  "attachment/filterAttachments",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.filterAttachmentsURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// PUT =>  FILTER ATTACHMENT
export const updateAttachmentsServices = createAsyncThunk(
  "attachment/updateAttachmentsServices",
  async ({ data, id }, thunkAPI) => {
    try {
      const response = await axios.put(
        `${URLS.attachmentsURL}/${id}/update`,
        data
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getAttachmentByClient = createAsyncThunk(
  "attachment/getAttachmentByClient",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(
        `${URLS.attachmentsClientURL}/${params.id}`,
        {
          params: {
            ...params,
          },
        }
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  UPLOAD ATTACHMENT FOR CLIENT
export const uploadAttachmentClientServices = createAsyncThunk(
  "attachment/uploadAttachmentClientServices",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        `${URLS.uploadAttachmentsClientURL}/${data.id}/create/`,
        data.formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// PUT =>  change name
export const changeAttachmentClientServices = createAsyncThunk(
  "attachment/changeAttachmentClientServices",
  async ({ id, data }, thunkAPI) => {
    try {
      const response = await axios.put(
        `${URLS.changeAttachmentsClientURL}/${id}/`,
        data
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  send by email
export const sendAttachmentClientServices = createAsyncThunk(
  "attachment/sendAttachmentClientServices",
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get(
        `${URLS.sendAttachmentsClientURL}/${id}/send_by_email/`
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const deleteAttachmentClientServices = createAsyncThunk(
  "attachment/deleteAttachmentClientServices",
  async (id, thunkAPI) => {
    try {
      const response = await axios.delete(
        `${URLS.uploadAttachmentsClientURL}/${id}/delete/`
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
