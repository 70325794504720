import { createAsyncThunk } from "@reduxjs/toolkit";
// urls
import * as URLS from "../../urls";
//axios
import axios from "../../utils/axios";
//_________________________________________________

// GET =>  GET ALL COURTS SESSION SERVICE
export const getAllCourtSessionService = createAsyncThunk(
  "sessions/getAllCourtSessionService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(URLS.getAllCourtSessionsURL, {
        params: {
          ...params,
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// POST =>  FILTER COURTS SERVICE
export const filterCourtsService = createAsyncThunk(
  "sessions/filterCourtsService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.post(
        URLS.filterCourtsSessionURL,
        params?.data,
        {
          params: { ...params?.params },
        }
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  FILTER Experts SERVICE
export const filterExpertsService = createAsyncThunk(
  "sessions/filterExpertsService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.filterCourtsSessionURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// POST =>  FILTER Administrative SERVICE
export const filterAdministrativeService = createAsyncThunk(
  "sessions/filterAdministrativeService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.filterCourtsSessionURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// POST =>  FILTER Excute SERVICE
export const filterExcuteService = createAsyncThunk(
  "sessions/filterExcuteService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.filterCourtsSessionURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// POST =>  DELETE Excute SERVICE
export const deleteSessionService = createAsyncThunk(
  "sessions/deleteSessionService",
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.delete(
        `${URLS.deleteSessionService}${id}/delete/`
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// POST =>  DELETE Excute SERVICE
export const updateSessionService = createAsyncThunk(
  "sessions/updateSessionService",
  async (data, thunkAPI) => {
    try {
      console.log("data in service", data.data);
      const response = await axios.put(
        `${URLS.update_session}/${data?.court_session_id}`,
        data?.data
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// PUT =>  EDIT update_procedure_statement
export const updateProsedureStatement = createAsyncThunk(
  "sessions/updateProsedureStatement",
  async ({ id, data }, thunkAPI) => {
    try {
      const response = await axios.put(
        `${URLS.court_sessions}/${id}${URLS.update_procedure_statement}`,
        data
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  GET PROCEDURES
export const getProceduresService = createAsyncThunk(
  "sessions/getProceduresService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(URLS.proceduresSessionsURL, {
        params: { ...params },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
