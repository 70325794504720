import React, {useRef} from 'react';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export const generatePDF = (elementId, padding, fileName) => {
  const name = fileName || new Date().toDateString();
  const element = document.getElementById(elementId);
  const imagePadding = padding ?? 2;

  if (element) {
    html2canvas(element, {
      useCORS: true,
      scale: 3,
      backgroundColor: "#FFFFFF",
      dpi: 300,
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png', 1.0);
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'pt',
        format: 'a4'
      });

      const pdfWidth = pdf.internal.pageSize.getWidth() - imagePadding * 2;
      const pdfHeight = ((canvas.height * pdfWidth) / canvas.width);

      pdf.addImage(imgData, 'PNG', imagePadding, imagePadding, pdfWidth, pdfHeight);
      pdf.save(`${name}.pdf`);
    });
  } else {
    console.error('Element not found');
  }
};


export const getCurrentDate = () => {
  return new Date().toLocaleDateString('en-GB');
};

export const getCurrentTime = () => {
  return new Date().toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true,
  });
};

export const extractDate = (datetimeString) => {
  if (datetimeString)
    return datetimeString.split('T')[0];
  else return getCurrentDate();
};

