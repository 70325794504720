import { createSlice } from "@reduxjs/toolkit";
import {
  getInvoicesService,
  createPaymentService,
  createStatementsService,
  createPaymentReceiptService,
  getMattersByClient,
  createFinanceFilterService,
  exportStatementsService,
  getImportExportsService,
  getPaymentReceiptsListService,
  getGeneralExpensesService,
  createGeneralExpensesService,
  sendEmailToClientService,
  getCustudyExpenseService,
  createExpensesCustudy,
  expenseStatementsService,
  createExpenseMatterService,
  checkExpense,
  createStatementsIndividualService,
  createStatementsCompanyService,
  getExpensesMattersService,
  getAllContractsService,
  handOverRequestService,
  getAssetsService,
  getAssetsTypeService,
  createAssetService,
  getInvoiceForContractService,
  getAccountsService,
  getTreeAcountsService,
  getJournalService,
  getDebtorsService,
  createDeptorService,
  getFromAccountService,
  getTypeExpenseService,
  createTypeExpenseService,
  getTypeAssetService,
  getReverseService,
  getMovementsReverseService,
  createReverseService,
  getWithdrawReverseService,
  collectDeptorService,
  createDebtorsCollectService,
  createWithdrawReverseService,
  //
  getMattersAndContractByClient,
  getMattersWithoutContractByClient,
  getEmployeeFacilityService,
  getStatusChoiceService,
  getTypeChoiceService,
  getSalaryService,
  getBounsesService,
  creteBounsesService,
  getBounsesChoiceService,
  generateSalaryReportService,
  deleteBounsesService,
  createSalaryService,
  getTotalSalaryForPayrollService,
  getPaymentTypeChoiceService,
  changeBounsesStatusService,
  editBounsesService,
  createEmployeeLiabilitiesService,
  collectEmployeeLiabilitiesService,
  getEmployeeLiabilitieByIdService,
  editEmployeeLiabilitiesService,
  deleteEmployeeLiabilitiesService,
  ReciveRequestService,
  updateJournalStatentService,
  getRootTreeAccountService,
  getChildrenByIdTreeAccountService,
  getReportsOfFinanceService,
} from "../services/finance_service";

import {
  invoicesColumnsList,
  voucherColumnsList,
  AccountsColumnsList,
  genralExpenseColumnsList,
  contractColumnList,
  contractClientColumnList,
  invoicesClientColumnsList,
} from "../../utils/columnsLists";
import { toast } from "react-toastify";

// ----------------------------------------------------------------------

const initialState = {
  isLoadingInvoices: false,
  isLoadingCreate: false,
  isLoading: false,
  invoicesList: [],
  //
  isLoadingExpenses: false,
  ExpensesList: [],
  //
  isLoadingCreateInvoice: false,
  //
  isLoadingCreateExpense: false,
  invoice: null,
  invoicesColumnsList: invoicesColumnsList,
  voucherColumnsList: voucherColumnsList,
  isLoadinggetMatters: false,
  mattersByClient: [],
  AccountsColumnsList: AccountsColumnsList,
  count: null,
  total_cost: null,
  dataToExport: {},
  generalExpenseColumnsList: genralExpenseColumnsList,
  paymentRecipitsList: [],
  generalExpenseList: [],
  total: null,
  expensesCount: null,
  invoiceId: null,
  isLoadingClientEmail: false,
  invoicesCount: null,
  custudyExpense: [],
  summery: {},
  custudyExpenseCount: null,
  expenseData: {},
  expenseStatementsCount: null,
  expenseStatements: [],
  isLoadingExSt: false,
  isLoadingCreateExMat: false,
  isLoadingCheck: false,
  ExpenseId: null,
  expensesMatterCount: null,
  expensesClient: null,
  expenses_count: null,
  expenses_status: null,
  expenses_total: null,
  expensesMatter: null,
  allContracts: [],
  columnsContractsList: contractColumnList,
  contractsCount: null,
  columnsContractsClientList: contractClientColumnList,

  invoicesClientColumnsList: invoicesClientColumnsList,
  expenseStatus: null,
  employee: null,
  isLoadingHandOver: false,
  isLoadingReciveRequest: false,
  assetsList: [],
  assetsType: [],
  metaAssets: null,
  contractInvoices: null,
  accountsList: [],
  metaAccountsList: [],
  treeList: [],
  journalList: [],
  metaJournal: null,
  debtorList: [],
  metaDeptor: null,
  fromAccountList: [],
  isLoadingAccounts: false,
  isLoadingType: false,
  typeList: [],
  isLoadingCreateType: false,
  //
  isLoadingTypeAsset: false,
  typeAssetList: [],
  reverseList: [],
  metaReverse: null,
  journalData: null,
  reverseId: null,
  movmentReverse: [],
  metaMovmentReverse: null,
  debtorId: null,
  metaContracts: null,
  //
  mattersContractsByClient: [],
  metaGeneralList: null,
  isAddContract: false,
  isLoadingContract: false,
  mattersWithoutByClient: [],
  metaGeneralList: null,
  isAddContract: false,
  employeeFacility: [],
  metaEmployee: null,
  typeChoice: [],
  statusChoice: [],
  metaSalary: null,
  salary: [],
  bounsList: [],
  metaBouns: null,
  bounsesChoice: [],
  dateOfSalary: null,
  dateOfBounes: null,
  dataOfGenerateSalaryReport: {},
  isLoadingDelete: false,
  totalSalary: null,
  paymentChoice: [],
  bounesDetailes: {},
  isLoadingStatus: false,
  isLoadingEdit: false,
  currentTabFinance: "0",
  summary: null,
  liabilityId: null,
  employeeLiabilityInfo: {},
  content: "payment_status",
  rootTreeAccount: [],
  isLoadingRoot: false,
  //
  childrenTreeAccount: [],
  isLoadingChildren: false,
  //
  childId: "",
  childNext: null,
  dataOfInviceToFilter: {},
  page: 1,
  sumOfExpensese:0,
  sumOfInstallments:0
};

const slice = createSlice({
  name: "finance",
  initialState,
  reducers: {
    resetMattersContract: (state, action) => {
      state.mattersContractsByClient = null;
    },
    setSumOfExpenseses: (state, action) => {
      state.sumOfExpensese = action.payload;
    },
    setSumOfInstallments: (state, action) => {
      state.sumOfInstallments = action.payload;
    },
    setDataToFilter: (state, action) => {
      state.dataOfInviceToFilter = {
        ...state.dataOfInviceToFilter,

        ...action.payload,
      };

      console.log("data to invoice is ");
      console.log(state.dataOfInviceToFilter);
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setInvoceId: (state, action) => {
      state.invoice = action.payload;
    },
    setIsAddContract: (state, action) => {
      state.isAddContract = action.payload;
    },
    setInvoicesColumnsList: (state, action) => {
      state.invoicesColumnsList = action.payload;
    },
    setVoucherColumnsList: (state, action) => {
      state.voucherColumnsList = action.payload;
    },
    setResetMatterList: (state, action) => {
      state.mattersByClient = [];
    },

    setAccountColumnsList: (state, action) => {
      state.AccountsColumnsList = action.payload;
    },
    setDataToExport: (state, action) => {
      state.dataToExport = action.payload;
    },

    setGeneralExpenseColumnsList: (state, action) => {
      state.generalExpenseColumnsList = action.payload;
    },

    setContractClolumnColumnsList: (state, action) => {
      state.columnsContractsList = action.payload;
    },

    setVoucherId: (state, action) => {
      state.invoiceId = action.payload;
    },
    setExpenseData: (state, action) => {
      state.expenseData = action.payload;
    },
    setExpenseId: (state, action) => {
      state.ExpenseId = action.payload;
    },
    setExpenseStatus: (state, action) => {
      state.expenseStatus = action.payload;
    },
    setEmployee: (state, action) => {
      state.employee = action.payload;
    },

    setContractColumnsList: (state, action) => {
      state.columnsContractsList = action.payload;
    },

    setContractClientColumnsList: (state, action) => {
      state.columnsContractsClientList = action.payload;
    },

    setInvoicesClientColumnsList: (state, action) => {
      state.invoicesClientColumnsList = action.payload;
    },
    setJournalData: (state, action) => {
      state.journalData = action.payload;
    },
    setRevId: (state, action) => {
      state.reverseId = action.payload;
    },
    setDebtorId: (state, action) => {
      state.debtorId = action.payload;
    },
    setDateOfSalary: (state, action) => {
      state.dateOfSalary = action.payload;
    },
    setDateOfBounes: (state, action) => {
      state.dateOfBounes = action.payload;
    },
    setDataOfGenerateSalary: (state, action) => {
      state.dataOfGenerateSalaryReport = {
        year_month: state.dateOfSalary,
        ...state.dataOfGenerateSalaryReport,
        ...action.payload,
      };
    },
    setTotalSalary: (state, action) => {
      state.totalSalary = null;
    },
    setBounesDetailes: (state, action) => {
      state.bounesDetailes = action.payload;
    },
    setCuurentFinanceTab: (state, action) => {
      state.currentTabFinance = action.payload;
    },
    setLiabilityId: (state, action) => {
      state.liabilityId = action.payload;
    },

    setContent: (state, action) => {
      state.content = action.payload;
    },

    setChidrenId: (state, action) => {
      state.childId = action.payload;
    },
  },
  extraReducers: {
    // ==> GET INVOICES
    [getInvoicesService.fulfilled]: (state, action) => {
      state.isLoadingInvoices = false;
      state.invoicesList = action.payload?.data?.data;
      state.invoicesCount = action.payload.data?.count;
    },
    [getInvoicesService.rejected]: (state, action) => {
      state.isLoadingInvoices = false;
    },
    [getInvoicesService.pending]: (state) => {
      state.isLoadingInvoices = true;
    },

    // ==> CREATE PAYMENT
    [createPaymentService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createPaymentService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createPaymentService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    // ==> CREATE STATEMENT
    [createStatementsService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createStatementsService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createStatementsService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    // POST =>  CREATE PAYMENT RECEPIPT
    [createPaymentReceiptService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createPaymentReceiptService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createPaymentReceiptService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    // GET MATTERS FOR CLIENT
    [getMattersByClient.fulfilled]: (state, action) => {
      console.log("fulfilled");
      state.isLoadinggetMatters = false;
      state.mattersByClient = action.payload?.data?.data;
      state.count = action.payload?.data?.count;
    },
    [getMattersByClient.rejected]: (state, action) => {
      console.log("rejected");
      state.isLoadinggetMatters = false;
    },
    [getMattersByClient.pending]: (state) => {
      console.log("pending");
      state.isLoadinggetMatters = true;
    },

    [createFinanceFilterService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.invoicesList = action.payload.data.data;
      state.invoicesCount = action.payload.data.count;
    },
    [createFinanceFilterService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [createFinanceFilterService.pending]: (state) => {
      state.isLoading = true;
    },

    [exportStatementsService.fulfilled]: (state, action) => {
      console.log(action.payload.data?.data);
      state.isLoadingCreate = false;

      state.expenses = action.payload.data?.data;
      state.count = action.payload?.count;
      state.total_cost = action.payload?.data?.total_cost;
    },
    [exportStatementsService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [exportStatementsService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    [getImportExportsService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [getImportExportsService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [getImportExportsService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    //getPaymentReceiptsListService

    [getPaymentReceiptsListService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.paymentRecipitsList = action.payload.data?.data;
      state.count = action.payload.data.count;
    },
    [getPaymentReceiptsListService.rejected]: (state, action) => {
      state.isLoading = false;
      toast.error(action.payload.errors[0] || "حدث خطأ");
    },
    [getPaymentReceiptsListService.pending]: (state) => {
      state.isLoading = true;
    },

    //getPaymentReceiptsListService

    [getGeneralExpensesService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.generalExpenseList = action.payload.data?.data?.general_expenses;
      state.metaGeneralList = action.payload.data;
      state.total = action.payload.data?.data?.total;
      state.expensesCount = action.payload.data?.count;
    },
    [getGeneralExpensesService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getGeneralExpensesService.pending]: (state) => {
      state.isLoading = true;
    },

    //createGeneralExpensesService

    [createGeneralExpensesService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createGeneralExpensesService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
      toast.error(action.payload.errors[0] || "حدث خطأ");
    },
    [createGeneralExpensesService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    // ==> send email to client
    [sendEmailToClientService.fulfilled]: (state, action) => {
      state.isLoadingClientEmail = false;
    },
    [sendEmailToClientService.rejected]: (state, action) => {
      state.isLoadingClientEmail = false;
    },
    [sendEmailToClientService.pending]: (state) => {
      state.isLoadingClientEmail = true;
    },

    [getCustudyExpenseService.pending]: (state) => {
      state.isLoadingExpenses = true;
    },
    [getCustudyExpenseService.fulfilled]: (state, action) => {
      state.isLoadingExpenses = false;
      state.custudyExpense = action.payload.data.data.data;
      state.custudyExpenseCount = action.payload.data.count;
      state.summery = action.payload.data.data.summery;
    },
    [getCustudyExpenseService.rejected]: (state, action) => {
      state.isLoadingExpenses = false;
    },
    [createExpensesCustudy.fulfilled]: (state, action) => {
      state.isLoadingCreateExpense = false;
    },
    [createExpensesCustudy.rejected]: (state, action) => {
      state.isLoadingCreateExpense = false;
    },
    [createExpensesCustudy.pending]: (state) => {
      state.isLoadingCreateExpense = true;
    },

    // GET =>     EXPORT EXPENS STATMENTS

    [expenseStatementsService.fulfilled]: (state, action) => {
      state.isLoadingExSt = false;
      state.expenseStatements = action.payload.data.data;
      state.expenseStatementsCount = action.payload.data.count;
    },
    [expenseStatementsService.rejected]: (state, action) => {
      state.isLoadingExSt = false;
    },
    [expenseStatementsService.pending]: (state) => {
      state.isLoadingExSt = true;
    },
    // POST =>     CREATE EXPENS STATMENTS

    [createExpenseMatterService.fulfilled]: (state, action) => {
      state.isLoadingCreateExMat = false;
    },
    [createExpenseMatterService.rejected]: (state, action) => {
      state.isLoadingCreateExMat = false;
    },
    [createExpenseMatterService.pending]: (state) => {
      state.isLoadingCreateExMat = true;
    },
    // GET =>     CHECK EXPENS IF EXSIST

    [checkExpense.fulfilled]: (state, action) => {
      state.isLoadingCheck = false;
    },
    [checkExpense.rejected]: (state, action) => {
      state.isLoadingCheck = false;
    },
    [checkExpense.pending]: (state) => {
      state.isLoadingCheck = true;
    },

    // POST =>     CREATE EXPENS STATMENTS
    [createStatementsIndividualService.fulfilled]: (state, action) => {
      state.ExpenseId = action.payload.data.data.id;
      state.isLoadingCreate = false;
    },
    [createStatementsIndividualService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createStatementsIndividualService.pending]: (state) => {
      state.isLoadingCreate = true;
    },
    // POST =>     CREATE EXPENS STATMENTS
    [createStatementsCompanyService.fulfilled]: (state, action) => {
      state.ExpenseId = action.payload.data.data.id;
      state.isLoadingCreate = false;
    },
    [createStatementsCompanyService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createStatementsCompanyService.pending]: (state) => {
      state.isLoadingCreate = true;
    },
    [getExpensesMattersService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.expensesMatter = action.payload.data.data.expense_matter;
      state.expenses_total = action.payload.data.data.summary.expenses_total;
      state.expenses_status = action.payload.data.data.summary.status;
      state.expenses_count = action.payload.data.data.summary.expenses_count;
      state.expensesClient = action.payload.data.data.summary.client;
      state.expensesMatterCount = action.payload.data.count;
    },
    [getExpensesMattersService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getExpensesMattersService.pending]: (state) => {
      state.isLoading = true;
    },

    // GET =>     GET ALL CONTRACT
    [getAllContractsService.fulfilled]: (state, action) => {
      state.isLoadingContract = false;
      state.allContracts = action?.payload?.data?.data;
      state.metaContracts = action?.payload?.data;
    },
    [getAllContractsService.rejected]: (state, action) => {
      state.isLoadingContract = false;
    },
    [getAllContractsService.pending]: (state) => {
      state.isLoadingContract = true;
    },

    // PUT =>  HAND OVER TO THE RESPONSIBLE
    [handOverRequestService.fulfilled]: (state, action) => {
      state.isLoadingHandOver = false;
    },
    [handOverRequestService.rejected]: (state, action) => {
      state.isLoadingHandOver = false;
    },
    [handOverRequestService.pending]: (state) => {
      state.isLoadingHandOver = true;
    },

    // GET =>  GET ASSETS SERVICE
    [getAssetsService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.metaAssets = action.payload?.data;
      state.assetsList = action.payload?.data?.data;
    },
    [getAssetsService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getAssetsService.pending]: (state) => {
      state.isLoading = true;
    },

    // GET =>  GET ASSETS SERVICE
    [getAssetsTypeService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.assetsType = action?.payload?.data?.data;
    },
    [getAssetsTypeService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getAssetsTypeService.pending]: (state) => {
      state.isLoading = true;
    },

    // CREATE =>  CREATE ASSET SERVICE
    [createAssetService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createAssetService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createAssetService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    // CREATE =>  CREATE ASSET SERVICE
    [getInvoiceForContractService.fulfilled]: (state, action) => {
      state.isLoadingInvoices = false;
      state.contractInvoices = action.payload.data?.results;
    },
    [getInvoiceForContractService.rejected]: (state, action) => {
      state.isLoadingInvoices = false;
    },
    [getInvoiceForContractService.pending]: (state) => {
      state.isLoadingInvoices = true;
    },

    // CREATE =>  CREATE ASSET SERVICE
    [getAccountsService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.accountsList = action.payload.data?.data;
      state.metaAccountsList = action.payload.data;
    },
    [getAccountsService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getAccountsService.pending]: (state) => {
      state.isLoading = true;
    },

    //GET => GET TREE ACOUNTS SERVICE
    [getTreeAcountsService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.treeList = action.payload.data?.data;
      state.count = action.payload?.data?.count;
      state.treeLastPage = action.payload?.data?.last_page;
      state.treeNext = action.payload?.data?.next;
    },
    [getTreeAcountsService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getTreeAcountsService.pending]: (state) => {
      state.isLoading = true;
    },

    //GET => GET TREE ACOUNTS SERVICE
    [getJournalService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.journalList = action.payload.data?.data;
      state.metaJournal = action.payload.data;
    },
    [getJournalService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getJournalService.pending]: (state) => {
      state.isLoading = true;
    },

    //GET => GET DEPTOE ACOUNTS SERVICE
    [getDebtorsService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.debtorList = action.payload.data?.data?.data;
      state.metaDeptor = action.payload.data;
      state.summary = action.payload.data.data.summary;
    },
    [getDebtorsService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getDebtorsService.pending]: (state) => {
      state.isLoading = true;
    },

    //GET => GET DEPTOE ACOUNTS SERVICE
    [createDeptorService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createDeptorService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createDeptorService.pending]: (state) => {
      state.isLoadingCreate = true;
    },
    //GET => GET  ACOUNTS
    [getFromAccountService.fulfilled]: (state, action) => {
      state.isLoadingAccounts = false;
      state.fromAccountList = action.payload.data?.data;
      state.accountLastPage = action.payload?.data?.last_page;
      state.accountNext = action.payload?.data?.next;
    },
    [getFromAccountService.rejected]: (state, action) => {
      state.isLoadingAccounts = false;
    },
    [getFromAccountService.pending]: (state) => {
      state.isLoadingAccounts = true;
    },

    //GET => GET  Type expense
    [getTypeExpenseService.fulfilled]: (state, action) => {
      state.isLoadingType = false;
      state.typeList = action.payload.data?.data;
      state.typeLastPage = action.payload?.data?.last_page;
      state.typetNext = action.payload?.data?.next;
    },
    [getTypeExpenseService.rejected]: (state, action) => {
      state.isLoadingType = false;
    },
    [getTypeExpenseService.pending]: (state) => {
      state.isLoadingType = true;
    },

    // POST => Add New Type Expense
    [createTypeExpenseService.fulfilled]: (state, action) => {
      state.isLoadingCreateType = false;
    },
    [createTypeExpenseService.rejected]: (state, action) => {
      state.isLoadingCreateType = false;
    },
    [createTypeExpenseService.pending]: (state) => {
      state.isLoadingCreateType = true;
    },

    //GET => GET  Type asset
    [getTypeAssetService.fulfilled]: (state, action) => {
      state.isLoadingTypeAsset = false;
      state.typeAssetList = action.payload.data?.data;
      state.typeAssetLastPage = action.payload?.data?.last_page;
      state.typetAssetNext = action.payload?.data?.next;
    },
    [getTypeAssetService.rejected]: (state, action) => {
      state.isLoadingTypeAsset = false;
    },
    [getTypeExpenseService.pending]: (state) => {
      state.isLoadingTypeAsset = true;
    },

    //GET => GET DEPTOE ACOUNTS SERVICE
    [getReverseService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.reverseList = action.payload.data?.data?.data;
      state.metaReverse = action.payload.data;
      state.summary = action.payload.data?.data?.summary;
    },
    [getReverseService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getReverseService.pending]: (state) => {
      state.isLoading = true;
    },

    //POST => POST REVERSE
    [createReverseService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createReverseService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createReverseService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    //POST => COLLECT DEPTOR SERVICE
    [collectDeptorService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [collectDeptorService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [collectDeptorService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    //getMovementsReverseService
    [getMovementsReverseService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.movmentReverse = action.payload.data.data;
      state.metaMovmentReverse = action.payload.data;
    },
    [getMovementsReverseService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getMovementsReverseService.pending]: (state) => {
      state.isLoading = true;
    },

    //getMovementsReverseService
    [createDebtorsCollectService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createDebtorsCollectService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createDebtorsCollectService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    //createWithdrawReverseService

    [createWithdrawReverseService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createWithdrawReverseService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createWithdrawReverseService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    // GET MATTERS AND CONTRACT FOR CLIENT
    [getMattersAndContractByClient.fulfilled]: (state, action) => {
      state.isLoadinggetMatters = false;
      state.mattersContractsByClient = action.payload?.data?.data;
      state.count = action.payload?.data?.count;
    },
    [getMattersAndContractByClient.rejected]: (state, action) => {
      state.isLoadinggetMatters = false;
    },
    [getMattersAndContractByClient.pending]: (state) => {
      state.isLoadinggetMatters = true;
    },

    // GET MATTERS FOR CLIENT
    [getMattersWithoutContractByClient.fulfilled]: (state, action) => {
      state.isLoadinggetMatters = false;
      state.mattersWithoutByClient = action.payload?.data?.data;
      state.count = action.payload?.data?.count;
    },
    [getMattersWithoutContractByClient.rejected]: (state, action) => {
      state.isLoadinggetMatters = false;
    },
    [getMattersWithoutContractByClient.pending]: (state) => {
      state.isLoadinggetMatters = true;
    },

    // GET => GET EMPLOYEE FACILITY
    [getEmployeeFacilityService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.employeeFacility = action.payload?.data?.data?.data;
      state.summary = action.payload.data.data.summary;
      state.metaEmployee = action.payload?.data;
    },
    [getEmployeeFacilityService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getEmployeeFacilityService.pending]: (state) => {
      state.isLoading = true;
    },

    // GET => GET EMPLOYEE FACILITY
    [getEmployeeLiabilitieByIdService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.employeeLiabilityInfo = action.payload?.data?.data;
    },
    [getEmployeeLiabilitieByIdService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getEmployeeLiabilitieByIdService.pending]: (state) => {
      state.isLoading = true;
    },

    // GET => GET STATUS CHOICE
    [getStatusChoiceService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.statusChoice = action.payload?.data?.data;
    },
    [getStatusChoiceService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getStatusChoiceService.pending]: (state) => {
      state.isLoading = true;
    },

    // GET => GET TYPE CHOICE
    [getTypeChoiceService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.typeChoice = action.payload?.data?.data;
    },
    [getTypeChoiceService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getTypeChoiceService.pending]: (state) => {
      state.isLoading = true;
    },

    // GET => GET TYPE CHOICE
    [getSalaryService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.salary = action.payload?.data?.data;
      state.metaSalary = action.payload?.data;
    },
    [getSalaryService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getSalaryService.pending]: (state) => {
      state.isLoading = true;
    },

    // GET => GET Bounses SERVICE
    [getBounsesService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.bounsList = action.payload?.data?.data?.data;
      state.summary = action.payload.data.data.summary;
      state.metaBouns = action.payload?.data;
    },
    [getBounsesService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getBounsesService.pending]: (state) => {
      state.isLoading = true;
    },

    // POST => CREATE BOUNES
    [creteBounsesService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [creteBounsesService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [creteBounsesService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    // POST => CREATE BOUNES
    [getBounsesChoiceService.fulfilled]: (state, action) => {
      state.isLoadingChoice = false;
      state.bounsesChoice = action.payload.data.data;
    },
    [getBounsesChoiceService.rejected]: (state, action) => {
      state.isLoadingChoice = false;
    },
    [getBounsesChoiceService.pending]: (state) => {
      state.isLoadingChoice = true;
    },

    // POST => CREATE BOUNES
    [deleteBounsesService.fulfilled]: (state, action) => {
      state.isLoadingDelete = false;
    },
    [deleteBounsesService.rejected]: (state, action) => {
      state.isLoadingDelete = false;
    },
    [deleteBounsesService.pending]: (state) => {
      state.isLoadingDelete = true;
    },

    // POST => CREATE SALARY
    [createSalaryService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createSalaryService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createSalaryService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    // POST => CREATE SALARY
    [getTotalSalaryForPayrollService.fulfilled]: (state, action) => {
      state.isLoadingTotal = false;
      state.totalSalary = action.payload.data.data?.total_salary;
    },
    [getTotalSalaryForPayrollService.rejected]: (state, action) => {
      state.isLoadingTotal = false;
    },
    [getTotalSalaryForPayrollService.pending]: (state) => {
      state.isLoadingTotal = true;
    },

    // POST => CREATE SALARY
    [getPaymentTypeChoiceService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.paymentChoice = action.payload.data.data;
    },
    [getPaymentTypeChoiceService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getPaymentTypeChoiceService.pending]: (state) => {
      state.isLoading = true;
    },

    // EDIT => PUT BOUNSES SERVICE
    [changeBounsesStatusService.fulfilled]: (state, action) => {
      state.isLoadingStatus = false;
    },
    [changeBounsesStatusService.rejected]: (state, action) => {
      state.isLoadingStatus = false;
    },
    [changeBounsesStatusService.pending]: (state) => {
      state.isLoadingStatus = true;
    },

    // EDIT => PUT BOUNSES SERVICE
    [editBounsesService.fulfilled]: (state, action) => {
      state.isLoadingEdit = false;
    },
    [editBounsesService.rejected]: (state, action) => {
      state.isLoadingEdit = false;
    },
    [editBounsesService.pending]: (state) => {
      state.isLoadingEdit = true;
    },

    // CREATE => CREATE LIABLILITY
    [createEmployeeLiabilitiesService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createEmployeeLiabilitiesService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createEmployeeLiabilitiesService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    // CREATE => CREATE LIABLILITY
    [collectEmployeeLiabilitiesService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [collectEmployeeLiabilitiesService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [collectEmployeeLiabilitiesService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    // EDIT => EDIT LIABLILITY
    [editEmployeeLiabilitiesService.fulfilled]: (state, action) => {
      state.isLoadingEdit = false;
    },
    [editEmployeeLiabilitiesService.rejected]: (state, action) => {
      state.isLoadingEdit = false;
    },
    [editEmployeeLiabilitiesService.pending]: (state) => {
      state.isLoadingEdit = true;
    },

    // EDIT => EDIT LIABLILITY
    [deleteEmployeeLiabilitiesService.fulfilled]: (state, action) => {
      state.isLoadingDelete = false;
    },
    [deleteEmployeeLiabilitiesService.rejected]: (state, action) => {
      state.isLoadingDelete = false;
    },
    [deleteEmployeeLiabilitiesService.pending]: (state) => {
      state.isLoadingDelete = true;
    },

    // EDIT => EDIT LIABLILITY
    [ReciveRequestService.fulfilled]: (state, action) => {
      state.isLoadingReciveRequest = false;
    },
    [ReciveRequestService.rejected]: (state, action) => {
      state.isLoadingReciveRequest = false;
    },
    [ReciveRequestService.pending]: (state) => {
      state.isLoadingReciveRequest = true;
    },

    // EDIT => EDIT JOURNAL
    [updateJournalStatentService.fulfilled]: (state, action) => {
      state.isLoadingEdit = false;
    },
    [updateJournalStatentService.rejected]: (state, action) => {
      state.isLoadingEdit = false;
    },
    [updateJournalStatentService.pending]: (state) => {
      state.isLoadingEdit = true;
    },

    // get tree account
    [getRootTreeAccountService.fulfilled]: (state, action) => {
      state.isLoadingRoot = false;
      state.rootTreeAccount = action.payload?.data?.data;
    },
    [getRootTreeAccountService.rejected]: (state, action) => {
      state.isLoadingRoot = false;
    },
    [getRootTreeAccountService.pending]: (state) => {
      state.isLoadingRoot = true;
    },

    // get => Children by id
    [getChildrenByIdTreeAccountService.fulfilled]: (state, action) => {
      state.isLoadingChildren = false;
      state.childrenTreeAccount = action.payload?.data?.data;
      state.childNext = action.payload?.data?.next;
      state.childLastPage = action.payload?.data?.last_page;
    },
    [getChildrenByIdTreeAccountService.rejected]: (state, action) => {
      state.isLoadingChildren = false;
    },
    [getChildrenByIdTreeAccountService.pending]: (state) => {
      state.isLoadingChildren = true;
    },

    // get =>  Get Report Of Finance
    [getReportsOfFinanceService.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [getReportsOfFinanceService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getReportsOfFinanceService.pending]: (state) => {
      state.isLoading = true;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setInvoceId,
  setInvoicesColumnsList,
  setVoucherColumnsList,
  setResetMatterList,
  setAccountColumnsList,
  setDataToExport,
  setGeneralExpenseColumnsList,
  setContractClolumnColumnsList,
  setVoucherId,
  setExpenseData,
  setExpenseId,
  setContractColumnsList,
  setContractClientColumnsList,
  setInvoicesClientColumnsList,
  setExpenseStatus,
  setEmployee,
  setJournalData,
  setRevId,
  setDebtorId,
  setIsAddContract,
  setDateOfBounes,
  setDateOfSalary,
  setDataOfGenerateSalary,
  setTotalSalary,
  setBounesDetailes,
  setCuurentFinanceTab,
  setLiabilityId,
  setContent,
  setChidrenId,
  setDataToFilter,
  setPage,
  resetMattersContract,
  setSumOfExpenseses,
  setSumOfInstallments
} = slice.actions;
