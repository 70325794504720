import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles({
  tableContainer: {
    border: "1px solid #808080",
    borderRadius: "0",
  },
  headerRow: {
    backgroundColor: "#f5f5f5",
  },
  headerCell: {
    backgroundColor: "#f5f5f5 !important",
    color: "black",
    border: "1px solid #808080",
  },
  cell: {
    textAlign: "center !important",
    border: "1px solid #808080",
    color: "black",
  },
  footerCell: {
    color: "black",
    border: "1px solid #808080",
  },
  ltrText: {
    direction: "ltr !important",
    textAlign: "left !important",
  },
  dotedText: {
    display: 'inline-block',
    position: 'relative',
    // width: "50%",
    padding: "6px",
    '&:after': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: '1px',
      height: '1px',
      borderBottom: '3px dotted #b0a3a3',
    }
  },
  bigDotedText: {
    display: 'inline-block',
    position: 'relative',
    // width: "60%",
    padding: "8px",
    '&:after': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: '1px',
      height: '1px',
      borderBottom: '3px dotted #b0a3a3',
    }
  }
});

export default useStyles;
